<template>
  <div class="container">
    <header-back name='提现成功'></header-back>
    <section class="top44 suc-cen">
      <van-icon
        name="checked"
        size="2.4rem"
        color='#70C242 '
      />
      <h5>{{ title }}</h5>
      <!-- <p style="color: #979797; font-size: 12px; line-height: 20px">
        {{ title }}，可持续关注物流信息，如遇问题可联系客服15812340000
      </p> -->
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";

export default {
  components: { HeaderBack },
  data() {
    return {
      title: "提现成功",
    };
  },

  created() {},

  methods: {},
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
  box-sizing: border-box;
  text-align: center;
}
.suc-cen {
  padding: 15%;
  h5 {
    font-weight: 400;
    color: #292929;
    padding-top: 30px;
    padding-bottom: 16px;
  }
}
</style>
